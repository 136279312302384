import React, { useState, useCallback } from 'react';
import axios from 'axios';
import Papa from 'papaparse';
import { useDropzone } from 'react-dropzone';
import ProgressBar from '@ramonak/react-progress-bar';
import { CSVLink } from 'react-csv';

const CHUNK_SIZE = 200;

const FileUpload = ({ apiEndpoint }) => {
  const [fileName, setFileName] = useState(null);
  const [chunkQueue, setChunkQueue] = useState([]); // Array of chunks to upload
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [errorData, setErrorData] = useState([]);
  const [validData, setValidData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [uploadStatus, setUploadStatus] = useState([]); // Track upload status for each chunk

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setFileName(file.name);
    setProgress(0);
    setError(null);
    setErrorData([]);
    setValidData([]);
    setHeaders([]);
    setUploadStatus([]);
    handleFileProcessing(file);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: '.csv' });

  const handleFileProcessing = (file) => {
    const allChunks = [];
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const rows = results.data;
        for (let i = 0; i < rows.length; i += CHUNK_SIZE) {
          const chunk = rows.slice(i, i + CHUNK_SIZE);
          allChunks.push(chunk);
        }
        setChunkQueue(allChunks);
        setUploadStatus(new Array(allChunks.length).fill(false)); // Initialize upload status for each chunk
      },
    });
  };

  const handleChunkUpload = async (chunk, index) => {
    try {
      const responseData = await uploadChunk(chunk);

      // Update headers based on response data
      updateHeaders(responseData);

      // Handle errors and valid data
      if (responseData.errors && Array.isArray(responseData.errors)) {
        setErrorData((prev) => [...prev, ...responseData.errors]);
      }
      if (responseData.validData && Array.isArray(responseData.validData)) {
        setValidData((prev) => [...prev, ...responseData.validData]);
      }

      // Update progress and upload status for the current chunk
      setProgress((prev) => prev + Math.round((1 / chunkQueue.length) * 100));
      setUploadStatus((prevStatus) => {
        const newStatus = [...prevStatus];
        newStatus[index] = true; // Mark this chunk as uploaded
        return newStatus;
      });
    } catch (err) {
      setError(err.response ? err.response.data.error : 'Unknown error');
    }
  };

  const updateHeaders = (responseData) => {
    if (validData.length === 0 && responseData.validData && responseData.validData.length > 0) {
      const firstItem = responseData.validData[0];
      const dynamicHeaders = Object.keys(firstItem).map((key) => ({ label: key, key }));
      setHeaders((prevHeaders) => mergeHeaders(prevHeaders, dynamicHeaders));
    }

    if (errorData.length === 0 && responseData.errors && responseData.errors.length > 0) {
      const firstErrorItem = responseData.errors[0];
      const errorHeaders = Object.keys(firstErrorItem).map((key) => ({ label: key, key }));
      setHeaders((prevHeaders) => mergeHeaders(prevHeaders, errorHeaders));
    }
  };

  const mergeHeaders = (prevHeaders, newHeaders) => {
    return [...new Set([...prevHeaders.map((h) => h.key), ...newHeaders.map((d) => d.key)])].map(
      (key) => ({
        label: key,
        key,
      })
    );
  };

  const uploadChunk = async (chunk) => {
    try {
      const response = await axios.post(apiEndpoint, { data: chunk }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 400) {
        return error.response.data;
      } else {
        throw error;
      }
    }
  };

  const closeCanvas = () => {
    // Reloads the page and clears the state
    setFileName(null);
    setChunkQueue([]);
    setProgress(0);
    setError(null);
    setErrorData([]);
    setValidData([]);
    setHeaders([]);
    setUploadStatus([]);
    window.location.reload();
  };

  const csvData = [
    ...errorData.map((item) => ({
      ...item,
      error: item.error || 'Duplicate in database',
    })),
    ...validData.map((item) => ({
      ...item,
      error: 'success',
    })),
  ];

  return (
    <>
      <div {...getRootProps()} style={dropzoneStyle}>
        <input {...getInputProps()} />
        <p className="font20px">Drag 'n' drop a CSV file here, or click to select one</p>
      </div>
      {fileName && <p className="m-4 mb-0"><b>Uploading:</b> {fileName}</p>}
      {error && <p className="p-4" style={{ color: 'red' }}>{error}</p>}

      <ProgressBar className="pb-4 px-4 my-pg" completed={progress} />

      {chunkQueue.map((chunk, index) => (
        <button
          key={index}
          onClick={() => handleChunkUpload(chunk, index)}
          disabled={uploadStatus[index]} // Disable button if chunk has been uploaded
          className="btn btn-primary m-2"
        >
          Upload Chunk {index + 1} {uploadStatus[index] ? "(Uploaded)" : ""}
        </button>
      ))}

      {(errorData.length > 0 || validData.length > 0) && (
        <>
          {errorData.length > 0 && (
            <CSVLink
              data={csvData}
              headers={headers}
              filename="uploaded_data.csv"
              className="btn btn-danger w-75 m-4"
              style={{ margin: '0 190px' }}
            >
              Export Data
            </CSVLink>
          )}
          <button className="btn btn-secondary m-4" onClick={closeCanvas}>Close and Reload</button>
        </>
      )}
    </>
  );
};

const dropzoneStyle = {
  width: '92%',
  height: '200px',
  borderWidth: '2px',
  borderColor: '#666',
  borderStyle: 'dashed',
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  minWidth: '570px',
  margin: '0px 25px',
};

export default FileUpload;
